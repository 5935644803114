<template>
  <div style="position: relative">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />
    <div class="status">
      <el-select
        v-model="valueArea"
        filterable
        clearable
        placeholder="请选择区号(可搜索关键字)"
        style="width: 240px; margin-right: 10px"
        v-if="internationType"
      >
        <el-option
          v-for="item in options"
          :key="item.name"
          :label="item.name + item.tel"
          :value="item.tel"
        >
        </el-option>
      </el-select>
      <el-input placeholder="请输入要查询的手机号" v-model="phone"></el-input>
      <el-input
        placeholder="请输入要查询的身份证号"
        v-model="idNumber"
      ></el-input>

      <div>
        <el-button type="primary" @click="searchList">搜索</el-button>
        <el-button type="info" @click="reset">重置</el-button>
      </div>
    </div>

    <div class="table_con">
      <el-table :data="tableData" border style="width: 100%" height="100%">
        <el-table-column label="序号" type="index" width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="手机号" width="180">
          <template slot-scope="scope">
            {{ scope.row.phoneArea }} {{ scope.row.phone }}
          </template>
        </el-table-column>
        <el-table-column prop="realName" label="认证姓名" width="180">
        </el-table-column>
        <el-table-column prop="idCard" label="身份证号"> </el-table-column>
        <el-table-column prop="authCancelTime" label="解除认证时间">
        </el-table-column>
        <el-table-column prop="auditor" label="操作人"> </el-table-column>
      </el-table>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
      />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { hideLoading, showLoading } from "@/util/loading";
import { areaCodeData } from "@/util/areaCodeData";

export default {
  components: {
    Breadcrumb,
    Pagination,
  },
  inject: ["reload"],
  data() {
    return {
      valueArea: "",
      options: [],
      phone: "",
      idNumber: "",
      tableData: [],
      state: "",
      BreadcrumbCon: [
        {
          name: "日常管理",
        },
        {
          name: "实名认证",
        },
        {
          name: "解除实名认证列表",
        },
      ], // 面包屑数据

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 20, 50, 100],

      internationType: false,
      role: "",
      phoneAreaROLE: "",
    };
  },

  mounted() {
    this.options = areaCodeData;

    this.role = localStorage.getItem("ROLE");
    var strNation = this.role.includes("ROLE_nation");
    if (strNation) {
      this.internationType = true;
    }
    this.phoneAreaROLE = localStorage.getItem("phoneArea");

    showLoading();
    const opt = {
      url: reqUrl.destroyRealNameList,
      method: "POST",
      params: JSON.stringify({
        idCard: "",
        nonce: "",
        pageNum: 0,
        pageSize: 0,
        phone: "",
        phoneArea: this.phoneAreaROLE,
        sign: "",
        token: "",
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code == 0) {
          this.tableData = res.data.data.list;
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;
          hideLoading();
        } else if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(opt);
  },

  methods: {
    searchList() {
      if (this.phone || this.idNumber) {
        showLoading();
        const opt = {
          url: reqUrl.destroyRealNameList,
          method: "POST",
          params: JSON.stringify({
            idCard: this.idNumber,
            nonce: "",
            pageNum: 0,
            pageSize: 0,
            phone: this.phone,
            phoneArea: this.valueArea || this.phoneAreaROLE,
            sign: "",
            token: "",
          }),
          resFunc: (res) => {
            console.log(res.data);
            if (res.data.code == 0) {
              this.tableData = res.data.data.list;
              this.pageLength = res.data.data.total;
              this.pageSize = res.data.data.pageSize;
              hideLoading();
            } else if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            }
            hideLoading();
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
            hideLoading();
          },
        };
        request(opt);
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可搜索项",
        });
      }
    },

    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.destroyRealNameList,
        method: "POST",
        params: JSON.stringify({
          idCard: this.idNumber,
          nonce: "",
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
          phone: this.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          sign: "",
          token: "",
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.currentPage = data.pageIndex;
            hideLoading();
          }
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
          hideLoading();
        },
      };
      request(opt);
    },

    reset() {
      if (this.phone || this.idNumber || this.valueArea) {
        this.phone = "";
        this.idNumber = "";
        this.valueArea = "";
        this.refresh();
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可重置项",
        });
      }
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.status {
  display: flex;

  .el-input {
    width: 300px;
    margin-right: 10px;
  }
}

.el-select {
  width: 300px;
}
::v-deep .el-input.is-disabled .el-input__inner {
  background-color: #e6e7e7;
  border-color: #d3d4d6;
  color: #7c7d7e;
}
</style>
